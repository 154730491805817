<script setup lang="ts">
import { required } from '@vee-validate/rules';
import { computed, defineProps, reactive, ref, watch } from 'vue';

import CheckIcon from '@/components/base/assets/CheckIcon.vue';
import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import { InputFailedEvent } from '@/components/base/form/InlineError.vue';
import Collapse from '@/components/base/layout/Collapse.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';

const props = defineProps<{
  requiredOnly?: boolean;
}>();

const emit = defineEmits<{
  'input-failed': [InputFailedEvent];
}>();

const password = defineModel<string>();
const passwordInput = ref<{ $el: HTMLElement }>();
const showPassword = ref(false);
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

interface PasswordValidators {
  message: string;
  validate: (value: string) => boolean;
  status: boolean;
}

const passwordValidators: PasswordValidators[] = reactive([
  {
    message: 'At least 8 characters in length',
    status: false,
    validate: (value: string) => value.length >= 8,
  },
  {
    message: 'A upper case letter',
    status: false,
    validate: (value: string) => /[A-Z]+/.test(value),
  },
  {
    message: 'A lower case letter',
    status: false,
    validate: (value: string) => /[a-z]+/.test(value),
  },
  {
    message: 'A special character (!@./#$%~!`^&*-+=?)',
    status: false,
    validate: (value: string) => /[!"@./#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+/.test(value),
  },
]);

const { errorMessages } = useForm();

const rules: Record<'password', FlexibleMessageValidator<string>> = {
  password: (value) => {
    if (props.requiredOnly) {
      return required(value) || errorMessages.password.required;
    }

    // `password` is called with `undefined` when field is blurred, but we want to persist
    // the current error state in that case, so ignore if `undefined`.
    if (value !== undefined) {
      passwordValidators.forEach((passwordValidator, index) => {
        const isValid = passwordValidator.validate(value);
        // eslint-disable-next-line no-param-reassign
        passwordValidator.status = isValid;
      });
    }

    return passwordValidators.every((passwordValidator) => passwordValidator.status) || 'error';
  },
};

const inputErrors = ref<string>();

function onBlur(error: string) {
  inputErrors.value = error;
}

watch(
  inputErrors,
  (currentError, previousError) => {
    if (currentError && currentError.length > 0 && currentError !== previousError) {
      emit('input-failed', { field: 'Password', error: currentError });
    }
  },
  { deep: true },
);

const showPasswordValidators = ref(false);
const showPasswordValidatorIcons = ref(false);
watch(
  password,
  (value) => {
    if (value && value.length > 0) showPasswordValidatorIcons.value = true;
  },
  { once: true },
);

const formInputAttributes = computed<Record<'password', InputAttributes>>(() => ({
  password: {
    autocomplete: props.requiredOnly ? 'current-password' : 'new-password',
    placeholder: 'Password',
    name: 'password',
    type: showPassword.value ? 'text' : 'password',
  },
}));

const hidePasswordSvg = nutshell['img/hide_password.svg'];
const showPasswordSvg = nutshell['img/show_password.svg'];
</script>

<template>
  <div class="relative">
    <FormInput
      v-model="password"
      dataTest="create-account"
      :inputAttributes="formInputAttributes.password"
      name="password"
      placeholder="password"
      ref="passwordInput"
      showLabel
      showPlaceholder
      :suppressMessaging="!requiredOnly"
      :validator="rules.password"
      @blur="(errors) => onBlur(errors)"
      @focus.once="showPasswordValidators = true"
    >
      <template #fallbackIcon>
        <img
          class="w-4 cursor-pointer opacity-60"
          :alt="showPassword ? 'show password' : 'hide password'"
          aria-hidden="true"
          :src="showPassword ? hidePasswordSvg : showPasswordSvg"
          @click="toggleShowPassword"
        />
      </template>
    </FormInput>
    <Collapse v-if="!requiredOnly" :expanded="showPasswordValidators" class="mt-2">
      <ul data-test="password-validators" id="inputHelperText-password">
        <li
          v-for="(passwordValidator, index) in passwordValidators"
          :key="passwordValidator.message"
          class="flex items-center text-sm"
        >
          <Transition name="fade">
            <div v-if="showPasswordValidatorIcons" class="h-4 mr-1">
              <component
                :aria-describedby="`requirement-${index}`"
                :class="passwordValidator.status ? 'text-green-700' : 'text-red-700'"
                :is="passwordValidator.status ? CheckIcon : CloseIcon"
                role="img"
                :size="16"
              />
            </div>
          </Transition>
          <SmallBodyText class="text-neutral-600" :id="`requirement-${index}`">
            <span class="sr-only">
              {{ passwordValidator.status ? 'Requirement met:' : 'Requirement not met:' }}
            </span>
            {{ passwordValidator.message }}.
          </SmallBodyText>
        </li>
      </ul>
    </Collapse>
  </div>
</template>
