import * as sessionReplay from '@amplitude/session-replay-browser';

import { useInitialRequest } from '@/stores/initialRequest';
import { reportError } from '@/utils/reportError';

function shouldInitializeAmplitudeSessionReplay(): boolean {
  // eslint-disable-next-line no-underscore-dangle
  const initialRequest = (window as any).__PINIA_STATE__?.initialRequest as
    | ReturnType<typeof useInitialRequest>
    | undefined;

  const country = initialRequest?.geoIp?.country;
  const state = initialRequest?.geoIp?.state;
  const isUS = country === 'US';
  const ccpaStates = ['CA', 'VA', 'CO'];

  const isLikelyCCPARegion = isUS && (!state || ccpaStates.includes(state));

  const hasSessionReplayFlag =
    window.document.querySelector('body')?.classList.contains('sessionReplay') ?? false;

  return !isLikelyCCPARegion && hasSessionReplayFlag;
}

function initializeAmplitudeSessionReplay() {
  try {
    window.rudderanalytics.ready(async () => {
      await sessionReplay.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
        deviceId: window.rudderanalytics.getAnonymousId(),
        sessionId: window.rudderanalytics.getSessionId(),
        sampleRate: 100,
      }).promise;

      const originalTrack = window.rudderanalytics.track;
      window.rudderanalytics.track = (
        eventName: string,
        eventProperties: any,
        options: any,
        callback: any,
      ) => {
        const sessionReplayProperties = sessionReplay.getSessionReplayProperties();
        const updatedEventProperties = {
          ...eventProperties,
          ...sessionReplayProperties,
        };
        originalTrack(eventName, updatedEventProperties, options, callback);
      };

      const originalPage = window.rudderanalytics.page;
      window.rudderanalytics.page = (
        category: string,
        name: string,
        properties: any,
        options: any,
        callback: any,
      ) => {
        const sessionReplayProperties = sessionReplay.getSessionReplayProperties();
        const updatedProperties = {
          ...properties,
          ...sessionReplayProperties,
        };
        originalPage(category, name, updatedProperties, options, callback);
      };
    });
    // eslint-disable-next-line no-console
    console.log('Amplitude initialized');
  } catch (error) {
    reportError(error, 'Amplitude initialization failed');
  }
}

if (shouldInitializeAmplitudeSessionReplay()) {
  initializeAmplitudeSessionReplay();
}
