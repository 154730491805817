import mixpanel, { Mixpanel } from 'mixpanel-browser';

import { useInitialRequest } from '@/stores/initialRequest';
import { reportError } from '@/utils/reportError';

function shouldInitializeMixpanelSessionReplay(): boolean {
  // eslint-disable-next-line no-underscore-dangle
  const initialRequest = (window as any).__PINIA_STATE__?.initialRequest as
    | ReturnType<typeof useInitialRequest>
    | undefined;

  const country = initialRequest?.geoIp?.country;
  const state = initialRequest?.geoIp?.state;
  const isUS = country === 'US';
  const ccpaStates = ['CA', 'VA', 'CO'];

  const isLikelyCCPARegion = isUS && (!state || ccpaStates.includes(state));

  const hasSessionReplayFlag =
    window.document.querySelector('body')?.classList.contains('sessionReplay') ?? false;

  return !isLikelyCCPARegion && hasSessionReplayFlag;
}

function initializeMixpanelSessionReplay() {
  try {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      persistence: 'localStorage',
      record_block_selector: '',
      record_sessions_percent: 100,
      loaded: (mp: Mixpanel) => {
        window.rudderanalytics.ready(() => {
          const rudderAnonymousId = window.rudderanalytics.getAnonymousId();
          if (rudderAnonymousId) {
            mp.register({
              $device_id: rudderAnonymousId,
              distinct_id: `$device:${rudderAnonymousId}`,
            });
          }

          const originalTrack = window.rudderanalytics.track;
          window.rudderanalytics.track = (
            eventName: string,
            eventProperties: any,
            options: any,
            callback: any,
          ) => {
            // @ts-expect-error: @types/mixpanel-browser is outdated and missing this
            const sessionReplayProperties = mp.get_session_recording_properties();
            const updatedEventProperties = {
              ...eventProperties,
              ...sessionReplayProperties,
            };
            originalTrack(eventName, updatedEventProperties, options, callback);
          };

          const originalPage = window.rudderanalytics.page;
          window.rudderanalytics.page = (
            category: string,
            name: string,
            properties: any,
            options: any,
            callback: any,
          ) => {
            // @ts-expect-error: @types/mixpanel-browser is outdated and missing this
            const sessionReplayProperties = mp.get_session_recording_properties();
            const updatedProperties = {
              ...properties,
              ...sessionReplayProperties,
            };
            originalPage(category, name, updatedProperties, options, callback);
          };
        });
      },
    });

    // eslint-disable-next-line no-console
    console.log('Mixpanel initialized');
  } catch (error) {
    reportError(error, 'Mixpanel initialization failed');
  }
}

if (shouldInitializeMixpanelSessionReplay()) {
  initializeMixpanelSessionReplay();
}
